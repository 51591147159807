import {AppState} from '../Root.store'

import {UserProfileState} from './UserProfile.reducer'

export const getUserProfile = (state: AppState): UserProfileState => state.userProfile

export const getUserProfileCountry = (state: AppState) => getUserProfile(state).userProfile?.country

export const getUserProfileDefaultLocale = (state: AppState) =>
  getUserProfile(state).userProfile?.defaultLocale
