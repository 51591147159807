import {ResizeObserver} from '@juggle/resize-observer'
import {Box, Button, Paper} from '@mui/material'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useToggleLayer} from 'react-laag'
import {makeStyles} from 'tss-react/mui'

const selectTextOfNode = (node: HTMLDListElement) => {
  // @ts-expect-error FIXME
  if (document.body.createTextRange) {
    // @ts-expect-error FIXME
    const range = document.body.createTextRange()
    range.moveToElementText(node)
    range.select()
  } else if (window.getSelection) {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    selection?.removeAllRanges()
    selection?.addRange(range)
  } else {
    throw new Error('Could not select text. Browser not supported!')
  }
}

const clearSelection = () => {
  // @ts-expect-error FIXME
  const sel = window.getSelection ? window.getSelection() : document.selection
  if (sel) {
    if (sel.removeAllRanges) {
      sel.removeAllRanges()
    } else if (sel.empty) {
      sel.empty()
    }
  }
}

export const useStyles = makeStyles()(() => ({
  button: {
    outline: 'none'
  },

  dl: {
    margin: 0
  },

  dt: {
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.5)',

    '&:not(:first-of-type)': {
      marginTop: '10px'
    }
  },

  dd: {
    color: 'rgba(0, 0, 0, 0.8)',
    padding: 0,
    margin: 0
  }
}))

const baseUrl = 'https://gitlab.heidelbergcement.com/hcp/monorepo/'

// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const versionRegex =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[A-Za-z-][\dA-Za-z-]*)(?:\.(?:0|[1-9]\d*|\d*[A-Za-z-][\dA-Za-z-]*))*))?(?:\+([\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*))?$/

interface InfoOverlayProps {
  layerProps: any
  stage: string
  version: string
  buildId: string
  commit: string
  date: string
}

const InfoOverlay: React.FC<InfoOverlayProps> = ({layerProps, stage, version, commit, date}) => {
  const {
    classes: {dl, dt, dd}
  } = useStyles()
  dayjs.extend(localizedFormat)
  const formattedDate = date ? dayjs(date).format('LLL') : null

  // cg1 = major, cg2 = minor, cg3 = patch, cg4 = prerelease and cg5 = build-metadata
  const parsedVersion = (version ? versionRegex.exec(version) : []) as RegExpExecArray
  const [major, minor, patch, prerelease, build] = parsedVersion

  const branchName = prerelease ? prerelease.split('.')[0] : null
  const prereleaseVersion = prerelease ? prerelease.split('.')[1] : null

  const appInfoRef = useRef<HTMLDListElement>(null)

  const copyToClipBoard = () => {
    const {current} = appInfoRef

    try {
      // select text
      // @ts-expect-error FIXME
      selectTextOfNode(current)
      // copy
      document.execCommand('copy')
      // remove selection
      clearSelection()
    } catch {
      console.warn('Could not copy text to clipboard: Unsupported browser!')
    }
  }

  return (
    <Paper elevation={8} {...layerProps}>
      <Box p={2}>
        <dl ref={appInfoRef} className={dl}>
          <dt className={dt}>Version:</dt>
          <dd className={dd}>{version ? `${major}.${minor}.${patch}` : '---'}</dd>
          <dt className={dt}>Pre-Release:</dt>
          <dd className={dd}>{prerelease ? `Yes, Iteration ${prereleaseVersion}` : 'No'}</dd>
          <dt className={dt}>Pipeline:</dt>
          <dd className={dd}>
            {build ? <a href={`${baseUrl}/pipelines/${build}`}>{build}</a> : '---'}
          </dd>
          <dt className={dt}>Branch:</dt>
          <dd className={dd}>
            {branchName ? <a href={`${baseUrl}/tree/${branchName}`}>{branchName}</a> : '---'}
          </dd>
          <dt className={dt}>Stage:</dt>
          <dd className={dd}>{stage}</dd>
          <dt className={dt}>Commit:</dt>
          <dd className={dd}>
            {commit ? <a href={`${baseUrl}/commit/${commit}`}>{commit}</a> : '---'}
          </dd>
          <dt className={dt}>Date:</dt>
          <dd className={dd}>{formattedDate || '---'}</dd>
        </dl>
        <Button onClick={copyToClipBoard}>Copy to clipboard</Button>
      </Box>
    </Paper>
  )
}

interface AppInfoProps {
  stage: string
  commit: string
  date: string
  version: string
  buildId: string
}

const AppInfo: React.FC<AppInfoProps> = ({stage, commit, date, version, buildId}) => {
  const {
    classes: {button}
  } = useStyles()
  // cg1 = major, cg2 = minor, cg3 = patch, cg4 = prerelease and cg5 = build-metadata
  const parsedVersion = version ? versionRegex.exec(version) : []
  const [major, minor] = parsedVersion as any

  const publicVersion = `${major}.${minor}`
  const publicStage = stage === 'PROD' ? '' : stage

  const {t} = useTranslation()
  const [element, toggleLayerProps] = useToggleLayer(
    // render the layer
    ({layerProps, isOpen}) =>
      isOpen && (
        <InfoOverlay
          layerProps={layerProps}
          stage={stage}
          version={version}
          commit={commit}
          date={date}
          buildId={buildId}
        />
      ),
    // provide some options, like placement
    {
      placement: {
        possibleAnchors: ['TOP_CENTER', 'BOTTOM_CENTER', 'TOP_RIGHT', 'BOTTOM_RIGHT'],
        preferY: 'TOP',
        autoAdjust: true,
        snapToAnchor: true,
        triggerOffset: 20,
        scrollOffset: 16
      },
      closeOnOutsideClick: true,
      ResizeObserver
    }
  )

  return (
    <>
      {element}
      <span
        aria-label="App info"
        className={button}
        tabIndex={0}
        role="button"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (e.altKey) {
            toggleLayerProps.openFromMouseEvent(e)
          }
        }}
      >
        {t('appName')} {publicVersion} {publicStage}
      </span>
    </>
  )
}

export default AppInfo
