import {Interval, parseISO, set, eachMinuteOfInterval} from 'date-fns'
import {format as formatDate} from 'date-fns/fp'
import moment from 'moment'

// this should not be here since it is dependent on particular feature module
import {MINUTE_STEP} from '../OrderIntake/declarations/constants'
import {DeliveryTime} from '../OrderIntake/declarations/types'

export type TimeObject = {
  hours?: number
  minutes?: number
  seconds?: number
}

export const parseTime = (time: string): TimeObject => {
  const timeSplitted = time.split(':')
  const hours = Number.parseInt(timeSplitted[0])
  const minutes = Number.parseInt(timeSplitted[1])
  const seconds = Number.parseInt(timeSplitted[2])
  if (Number.isNaN(hours)) {
    console.warn(`Date ${time} could not be parsed`)
  }
  return {
    hours: Number.isNaN(hours) ? undefined : hours,
    minutes: Number.isNaN(minutes) ? undefined : minutes,
    seconds: Number.isNaN(seconds) ? undefined : seconds
  }
}

export const getTimeSlots = (interval: Interval, intervalInMins = MINUTE_STEP) => {
  return eachMinuteOfInterval(interval, {step: intervalInMins})
}

const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = 60

export const convertToMinutes = (time = '') => {
  const parsedTime = parseTime(time)
  const minutes =
    (parsedTime.hours ?? 0) * MINUTES_IN_HOUR +
    (parsedTime.minutes ?? 0) +
    Math.trunc((parsedTime.seconds ?? 0) / SECONDS_IN_MINUTE)
  return minutes
}

export const setTime = (date: number | Date, time: string) => {
  return set(date, parseTime(time))
}

export const createTimeInterval = (
  isoDate: string,
  startTime: string,
  endTime: string
): Interval => {
  const date = parseISO(isoDate)
  return {
    start: setTime(date, startTime),
    end: setTime(date, endTime)
  }
}

export const getDuration = (hubDuration: string): Duration => {
  const durationSplitted = hubDuration.split(':')
  return {
    hours: Number.parseInt(durationSplitted[0]),
    minutes: Number.parseInt(durationSplitted[1]),
    seconds: Number.parseInt(durationSplitted[2])
  }
}

export const getCurrentBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone
export const getCurrentBrowserAbbrTimezone = () => {
  const currentDate = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short', hour12: true})
  const splitDate = currentDate.split(' ')
  return splitDate[splitDate.length - 1]
}

export const getTodayTzAwareDateFromTime = (time: string, timezone = ''): Date => {
  const currentTimeInTzString = `${moment().tz(timezone).format('YYYY-MM-DD')} ${time}`
  const currentTime = moment.tz(currentTimeInTzString, timezone).toDate()
  return currentTime
}

export const getTzAwareNow = (timezone?: string) =>
  (timezone ? moment().tz(timezone) : moment()).toDate()

export const isDateToday = (date: string, timezone?: string): boolean => {
  const today = getTzAwareNow(timezone)
  return moment.tz(date, timezone ?? '').format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD')
}

export const isCurrentTimezone = (timezone?: string) => {
  if (!timezone) return true
  return moment.tz(moment.tz.guess(true)).zoneAbbr() === moment.tz(timezone).zoneAbbr()
}

export const formatOutputTime = formatDate('HH:mm:ss')

export const formatTimeObject = (timeObject: TimeObject) => {
  return (
    timeObject.hours?.toString().padStart(2, '0') +
    ':' +
    timeObject.minutes?.toString().padStart(2, '0')
  )
}

export const isWholeDay = (interval: Interval, value: DeliveryTime) => {
  return (
    formatOutputTime(interval.start) === value.earliest &&
    formatOutputTime(interval.end) === value.latest
  )
}

export const formatTime = (time: string, timezone: string, valueFormatting: string) => {
  return moment
    .tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${time}`, timezone)
    .format(valueFormatting)
}

export const isIntervalWithinInterval = (
  innerFrom: string,
  innerTo: string,
  outerFrom: string,
  outerTo: string,
  overlapInterval: string
) => {
  const innerTimeFrom = parseTime(innerFrom)
  const innerTimeTo = parseTime(innerTo)

  const outerTimeFrom = parseTime(outerFrom)
  const outerTimeTo = parseTime(outerTo)

  const overlapIntervalTime = parseTime(overlapInterval)

  const withinCond1 =
    isTimeAfter(innerTimeFrom, outerTimeFrom) || isTimeSame(innerTimeFrom, outerTimeFrom)
  const withinCond2 = isTimeBefore(innerTimeTo, outerTimeTo) || isTimeSame(innerTimeTo, outerTimeTo)

  const leftOverCond1 = isTimeBefore(innerTimeFrom, outerTimeFrom)
  const leftOverCond2 = isTimeAfter(innerTimeTo, outerTimeFrom, overlapIntervalTime)

  const rightOverCond1 = isTimeBefore(innerTimeFrom, outerTimeTo, overlapIntervalTime)
  const rightOverCond2 = isTimeAfter(innerTimeTo, outerTimeTo)

  return (
    (withinCond1 && withinCond2) ||
    (leftOverCond1 && leftOverCond2) ||
    (rightOverCond1 && rightOverCond2)
  )
}

export const isTimeAfter = (
  controlledTime: TimeObject,
  boundaryTime: TimeObject,
  minInterval?: TimeObject
) => {
  const timeDiff = getTimeDiffInMinutes(controlledTime, boundaryTime)
  const minIntervalInMinutes = (minInterval?.hours ?? 0) * 60 + (minInterval?.minutes ?? 0)

  return timeDiff >= minIntervalInMinutes
}

export const isTimeBefore = (
  controlledTime: TimeObject,
  boundaryTime: TimeObject,
  minInterval?: TimeObject
) => {
  const timeDiff = getTimeDiffInMinutes(controlledTime, boundaryTime)
  const minIntervalInMinutes = (minInterval?.hours ?? 0) * 60 + (minInterval?.minutes ?? 0)

  return timeDiff < 0 && Math.abs(timeDiff) >= minIntervalInMinutes
}

export const isTimeSame = (controlledTime: TimeObject, boundaryTime: TimeObject) => {
  return (
    (controlledTime.hours ?? 0) === (boundaryTime.hours ?? 0) &&
    (controlledTime.minutes ?? 0) === (boundaryTime.minutes ?? 0)
  )
}

export const getTimeDiffInMinutes = (
  controlledTime: TimeObject,
  boundaryTime: TimeObject
): number => {
  return (
    (controlledTime.hours ?? 0) * 60 +
    (controlledTime.minutes ?? 0) -
    ((boundaryTime.hours ?? 0) * 60 + (boundaryTime.minutes ?? 0))
  )
}
