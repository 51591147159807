import {useHubQuery} from '../../QueryProvider'

export const usePersistedRequestForQuote = (siteNumber: string, businessLine: string) => {
  return useHubQuery('persistedRequestForQuote', [siteNumber, businessLine], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!siteNumber && !!businessLine
  })
}
