import axios from 'axios'

// eslint-disable-next-line max-params
export const checkIsActionRequired = (dispatch, caller, err, args) => {
  if (
    (err.response && (err.response.status === 401 || err.response.status >= 500)) ||
    (!err.response && !!err.request)
  ) {
    return {
      label:
        err.response && err.response.status === 401 ? 'error.action.login' : 'error.action.retry',
      callback: () => dispatch(caller(...args))
    }
  }
  return null
}

// eslint-disable-next-line max-params
export const shouldDataTableBeDisplayed = (items, isLookupActive, isFetching, customerId) => {
  // if (!isLookupActive || isFetching) return true
  // return !!(items && items.length)

  if (isLookupActive && !(items && items.length > 0) && !isFetching && !customerId) {
    return false
  }
  return true
}

// will cancel prev requests
// usage:
//   // global definition outside the action
//   const canceller = RequestCanceller()
//   /* ... */
//   // inside action
//   canceller.cancel('Cancel prev request due to new request')
//   api.get('<url>', { cancelToken: canceller.getToken() })
export const RequestCanceller = () => {
  let cancelToken
  return {
    getToken: () => {
      cancelToken = axios.CancelToken.source()
      return cancelToken.token
    },
    cancel: (message) => {
      cancelToken && cancelToken.cancel(message)
    },
    isCancel: (thrownError) =>
      thrownError.name === 'InvalidTokenError' || axios.isCancel(thrownError)
  }
}

// eslint-disable-next-line max-params
export const getErrorMessage = (scope, errorKey, dispatch, action, args) => {
  switch (errorKey) {
    case '500_INTERNAL_SERVER_ERROR':
    // fall through

    case '504_GATEWAY_TIMEOUT':
      return {
        key: scope,
        translationKey: `filterErrors.${errorKey}`,
        errorKey
      }

    case 'NETWORK_ERROR':
      return {
        key: scope,
        translationKey: `filterErrors.${errorKey}`,
        callback: dispatch ? () => dispatch(action(args)) : () => action(args),
        callbackTranslationKey: 'error.action.retry',
        errorKey
      }

    default:
      return {
        key: scope,
        translationKey: 'error.server',
        errorKey
      }
  }
}
