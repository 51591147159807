import React from 'react'
import {Helmet} from 'react-helmet-async'

import appleIcon114 from './apple-icon-114x114.png'
import appleIcon120 from './apple-icon-120x120.png'
import appleIcon144 from './apple-icon-144x144.png'
import appleIcon152 from './apple-icon-152x152.png'
import appleIcon180 from './apple-icon-180x180.png'
import appleIcon57 from './apple-icon-57x57.png'
import appleIcon60 from './apple-icon-60x60.png'
import appleIcon72 from './apple-icon-72x72.png'
import appleIcon76 from './apple-icon-76x76.png'
import browserconfig from './browserconfig.xml'
import favIcon16 from './favicon-16x16.png'
import favIcon32 from './favicon-32x32.png'
import favIcon96 from './favicon-96x96.png'
import favIconFallback from './favicon.ico'
import manifest from './site.webmanifest'

export default function Head() {
  const isStaging = process.env.REACT_APP_STAGE !== 'prod'
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57} />
      <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60} />
      <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72} />
      <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76} />
      <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114} />
      <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
      <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />
      <link rel="icon" type="image/png" sizes="96x96" href={favIcon96} />
      <link rel="icon" type="image/png" sizes="32x32" href={favIcon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favIcon16} />
      <link rel="manifest" href={manifest} />
      <link rel="shortcut icon" href={favIconFallback} />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="msapplication-config" content={browserconfig} />
      <meta name="msapplication-TileColor" content="#00374D" />
      <meta name="msapplication-TileImage" content="%PUBLIC_URL%/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="format-detection" content="telephone=no" />
      <meta
        name="description"
        content="Your online customer portal with information about your orders, invoices, test reports, and more. Hub covers your back office and site needs."
      />
      {isStaging ? <meta name="robots" content="noindex" /> : null}
    </Helmet>
  )
}
