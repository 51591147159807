import {useHubQuery} from '../../QueryProvider'

export const useGetOrderCancellationOptions = (
  country: string | null | undefined,
  salesOrg: string | undefined,
  shippingType: string | undefined
) => {
  return useHubQuery('orderCancellationOptions', [country, salesOrg, shippingType], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })
}
