import {useMutation} from 'react-query'

import {mutations} from '../../mutations'
import {hubQueryClient} from '../../QueryProvider'

export const usePersistContact = () => {
  return useMutation(mutations.persistContact, {
    onMutate: async ({siteNumber}) => {
      await hubQueryClient.invalidateQueries(['persistedContact', siteNumber])
    },
    onSuccess: (data, {siteNumber}) => {
      hubQueryClient.setQueryData(['persistedContact', siteNumber], data)
    }
  })
}
