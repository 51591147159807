import {useMediaQuery, useTheme} from '@mui/material'
import {Breakpoint} from '@mui/material/styles'

// since xs is 0 now, we are excluding it
type UseMediaQueryBreakpoints = Record<Exclude<Breakpoint, 'xs'>, boolean>

/**
 * @returns true or false for all breakpoints when screensize is matching breakpoint
 * sm: from 600 to 0
 * md: from 960 to 0
 * lg: from 1280 to 0
 * xl: from 1536 to 0
 */
export const useMediaQueryBreakpoints = (): UseMediaQueryBreakpoints => {
  const theme = useTheme()
  // matches from 600 to 0
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  // matches from 960 to 0
  const md = useMediaQuery(theme.breakpoints.down('md'))
  // matches from 1280 to 0
  const lg = useMediaQuery(theme.breakpoints.down('lg'))
  // matches from 1536 to 0
  const xl = useMediaQuery(theme.breakpoints.down('xl'))

  return {
    sm,
    md,
    lg,
    xl
  }
}
