import {useHubQuery} from '../../QueryProvider'

export const usePersistedOrderRequests = (siteNumber: string) => {
  return useHubQuery('persistedOrderRequests', [siteNumber], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!siteNumber
  })
}
