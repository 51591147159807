import {makeStyles} from 'tss-react/mui'

export const useContainerStyles = makeStyles()((theme) => ({
  pageContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6)
    },
    paddingTop: '0 !important'
  },
  pageComponentContainer: {
    borderRadius: '4px'
  }
}))
