import {useHubQuery} from '../../QueryProvider'

export const useNotValidatedOrderRequests = (customerId: string, stop: boolean) => {
  return useHubQuery('notValidatedOrderRequests', [customerId], {
    enabled: !!customerId,
    refetchInterval: stop ? false : 10000,
    retry: 3,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false
  })
}
