export enum PageNames {
  OVERVIEW = 'overview',
  ORDER_INTAKE = 'orderIntake',
  FINANCE_PAGE = 'invoices',
  INVOICE_DETAILS = 'invoiceDetails',
  PAYMENTS = 'payments',
  STATEMENTS = 'statements',
  ORDERS = 'orders',
  ORDER_DETAILS = 'orderDetails',
  TEST_REPORTS = 'testReports',
  GENERATE_CERTIFICATES = 'generateCertificates',
  RMC_TEST_CERTIFICATES = 'rmcTestCertificates'
}

export enum ScreenSizeLabel {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
  UNDEFINED = 'Undefined'
}

export enum CustomerFeedbackProgrammeAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  DISMISS = 'dismiss'
}
