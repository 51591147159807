import {useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {useHubQuery} from '../../QueryProvider'

export const useGetSlotConfigurations = () => {
  const userCountry = useSelector((state: AppState) => state.userProfile.userProfile?.country || '')
  return useHubQuery('slotConfigurations', [], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: userCountry === 'GB'
  })
}
