import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useSelector} from 'react-redux'
import {AppState} from '../Root.store'
import {User} from '@hconnect/apiclient'
import {getAvailableBusinessLines} from '../Roles/Roles.utils'
import {useRoles} from '../Roles'
import moment from 'moment'
import {PageNames} from '../common/constants'
import {useResponsiveBreakpoints} from '../Molecules/Responsive.utils'

export enum filterType {
  DATE = 'date',
  PAYMENTS = 'payments',
  YEAR = 'year',
  SUPPLIER_NAMES = 'supplierNames',
  CUSTOMERS = 'customers',
  CUSTOMER_SIMPLE_LOOKUP = 'customerSimpleLookup',
  PROJECT_SITES = 'projectSites',
  SITES = 'sites',
  PLANTS = 'plants',
  PRODUCTS = 'products',
  LOOKUP = 'lookup',
  OPEN_INVOICES = 'openInvoices',
  PAYMENT_STATUS = 'paymentStatus',
  SURCHARGES = 'surcharges',
  ORDER_STATUS = 'orderStatus',
  TEST_STATUS = 'testStatus'
}

export const useTrackFilterEvents = () => {
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {data: roles} = useRoles()
  const {screenSize} = useResponsiveBreakpoints()

  const {country} = user || {}
  const businessLines = roles ? getAvailableBusinessLines(roles) : []
  const commonFilterEventData = {product: 'hub', country, businessLines, screenSize}
  const eventName = 'hubFilter'

  const trackDateFilterEvent = (page: PageNames, dateRange) => {
    const startDate = moment(dateRange.startDate)
    const endDate = moment(dateRange.endDate)
    const daysTotal =
      dateRange.startDate && dateRange.endDate ? endDate.diff(startDate, 'days') + 1 : 0

    trackEvent(eventName, {
      ...commonFilterEventData,
      page,
      filterType: filterType.DATE,
      dateRange,
      daysTotal: daysTotal.toString(),
      cleared: false
    })
  }

  const trackSiteFilterEvent = (page: PageNames, siteId: string | undefined | null) => {
    trackEvent(eventName, {
      ...commonFilterEventData,
      page,
      filterType: filterType.SITES,
      siteId,
      cleared: !siteId
    })
  }

  const trackLookupFilterEvent = (page: PageNames, lookupEntity?: string, lookupValue?: string) => {
    trackEvent(eventName, {
      ...commonFilterEventData,
      page,
      filterType: filterType.LOOKUP,
      lookupCategory: lookupEntity,
      lookupValue: lookupValue,
      cleared: !lookupValue
    })
  }

  const trackFilterEvents = (eventData) => {
    trackEvent(eventName, {
      ...eventData,
      ...commonFilterEventData
    })
  }

  return {trackFilterEvents, trackDateFilterEvent, trackSiteFilterEvent, trackLookupFilterEvent}
}
