import {User} from '../../../apiclient/src'

import {
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UserProfileActions
} from './UserProfile.actions'

export interface UserProfileState {
  isFetching: boolean
  lastUpdated: string | null
  userProfile: User | null
  error: any
}

const initialState: UserProfileState = {
  isFetching: false,
  lastUpdated: null,
  userProfile: null,
  error: null
}

export const userProfileReducer = (state = initialState, action: UserProfileActions) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: new Date().toISOString(),
        userProfile: action.userProfile
      }

    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error.message
      }

    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false
      }

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: new Date().toISOString(),
        userProfile: action.userProfile
      }

    default:
      return state
  }
}
