export const amountToString = (amount: number): string => parseFloat(amount.toFixed(2)).toString()

export const roundInt = (value: number, toNearest: number): string => {
  return (Math.round(value / toNearest) * toNearest).toString()
}

export const roundFloat = (value: number, toNearest: number, fixed: number): string => {
  return (Math.round(value / toNearest) * toNearest).toFixed(fixed)
}

export const countDecimals = (value: number): number => {
  const decimals = value.toString().split('.')[1]
  return decimals ? decimals.length : 0
}
