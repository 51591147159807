import {useMutation} from 'react-query'

import {mutations} from '../../mutations'
import {hubQueryClient} from '../../QueryProvider'

export const usePersistRequestForQuote = () => {
  return useMutation(mutations.persistRequestForQuote, {
    onMutate: async ({siteNumber, businessLine}) => {
      await hubQueryClient.invalidateQueries(['persistedRequestForQuote', siteNumber, businessLine])
    },
    onSuccess: (data, {siteNumber, businessLine}) => {
      hubQueryClient.setQueryData(['persistedRequestForQuote', siteNumber, businessLine], data)
    }
  })
}
