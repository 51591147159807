import {AxiosResponse} from 'axios'

import {User} from '../../../apiclient/src'

export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST'
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE'
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'

type UserProperties = keyof User
interface FetchUserProfileSuccess {
  type: typeof FETCH_USER_PROFILE_SUCCESS
  username: string
  userProfile: User
}
interface FetchUserProfileRequest {
  type: typeof FETCH_USER_PROFILE_REQUEST
  username: string
}
interface FetchUserProfileFailure {
  type: typeof FETCH_USER_PROFILE_FAILURE
  username: string
  error: any
}

interface UpdateUserProfileRequest {
  type: typeof UPDATE_USER_PROFILE_REQUEST
  username: string
}

interface UpdateUserProfileSuccess {
  type: typeof UPDATE_USER_PROFILE_SUCCESS
  username: string
  userProfile: User
}

interface UpdateUserProfileFailure {
  type: typeof UPDATE_USER_PROFILE_FAILURE
  username: string
  error: any
}

export type UserProfileActions =
  | FetchUserProfileFailure
  | FetchUserProfileRequest
  | FetchUserProfileSuccess
  | UpdateUserProfileFailure
  | UpdateUserProfileRequest
  | UpdateUserProfileSuccess

const fetchUserProfileRequest = (username: string): UserProfileActions => ({
  type: FETCH_USER_PROFILE_REQUEST,
  username
})

const fetchUserProfileSuccess = (username: string, userProfile: User): UserProfileActions => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  username,
  userProfile
})

const fetchUserProfileFailure = (username: string, error: any): UserProfileActions => ({
  type: FETCH_USER_PROFILE_FAILURE,
  username,
  error
})

export const fetchUserProfile =
  (username: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchUserProfileRequest(username))

    try {
      const response: AxiosResponse<User> = await api.get(`/users/${username}`)
      const {data} = response
      dispatch(fetchUserProfileSuccess(username, data))
      return data
    } catch (err) {
      dispatch(fetchUserProfileFailure(username, err))
      return null
    }
  }

const updateUserProfileRequest = (username: string): UserProfileActions => ({
  type: FETCH_USER_PROFILE_REQUEST,
  username
})

const updateUserProfileSuccess = (username: string, userProfile: User): UserProfileActions => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  username,
  userProfile
})

const updateUserProfileFailure = (username: string, error: any): UserProfileActions => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  username,
  error
})

export const updateUserProfile =
  (username: string, value: string, property: UserProperties) =>
  async (dispatch, getState, {api}) => {
    dispatch(updateUserProfileRequest(username))

    try {
      const response: AxiosResponse<User> = await api.put(`/users/${username}`, {
        [property]: value
      })
      dispatch(updateUserProfileSuccess(username, response.data))
      return response.data
    } catch (err) {
      dispatch(updateUserProfileFailure(username, err))
      return null
    }
  }

export const updateUserProfileMultipleProperties =
  (username: string, args: Partial<Record<UserProperties, string>>) =>
  async (dispatch, getState, {api}) => {
    dispatch(updateUserProfileRequest(username))

    try {
      const response: AxiosResponse<User> = await api.put(`/users/${username}`, {
        ...args
      })
      dispatch(updateUserProfileSuccess(username, response.data))
      return response.data
    } catch (err) {
      dispatch(updateUserProfileFailure(username, err))
      return null
    }
  }
