/* eslint-disable global-require */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import {createRoot} from 'react-dom/client'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import {Provider} from './App.provider'
import {AppContainer} from './AppContainer'
import Localization from './Localization'
import {unregister} from './serviceWorker'

AppInsightsAdapter.init()
void Localization()

function render(RootContainer) {
  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)
  root.render(
    <Provider>
      <RootContainer />
    </Provider>
  )
}

if (process.env.NODE_ENV !== 'production') {
  const webpackModule = module as any
  if (webpackModule.hot) {
    webpackModule.hot.accept('./AppContainer', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const NextApp = require('./AppContainer').default
      render(NextApp)
    })
    webpackModule.hot.accept('./Localization', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const NextLocalization = require('./Localization').default
      NextLocalization()
    })
  }
}

render(AppContainer)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register(customSW)
unregister()
