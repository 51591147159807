import {trackEvent as track} from '@hconnect/common/logging/Analytics'

export type TrackEventData = {
  [key: string]: unknown
}

export const trackEvent = (key: string, obj: TrackEventData = {}) =>
  track(key, {
    product: 'hub',
    ...obj
  })
