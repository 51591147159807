import {useMutation} from 'react-query'

import {mutations} from '../../mutations'
import {hubQueryClient} from '../../QueryProvider'

export const usePostOrderRequestCollection = () => {
  return useMutation(mutations.postOrderRequestCollection, {
    onMutate: async () => {
      await hubQueryClient.invalidateQueries('orderRequests')
    }
  })
}
