import {useMutation} from 'react-query'

import {mutations} from '../../mutations'
import {hubQueryClient} from '../../QueryProvider'

export const useDismissFailedOrderRequest = () => {
  return useMutation(mutations.patchOrderRequest, {
    onMutate: () => {
      void hubQueryClient.invalidateQueries('orderRequests')
      void hubQueryClient.invalidateQueries('failedOrderRequests')
    }
  })
}
