import {useHubQuery} from '../../QueryProvider'

export const useGetCancellationRequestStatus = (
  orderItemNumber: string,
  orderItemPositionNumber: string,
  orderStatus: string
) => {
  return useHubQuery('cancellationRequestStatus', [orderItemNumber, orderItemPositionNumber], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    refetchInterval: orderStatus === 'cancelled' ? false : 30000
  })
}
