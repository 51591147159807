import {AxiosError} from 'axios'
import React from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
  useQuery,
  UseQueryOptions
} from 'react-query'

import {queries, Queries} from './queries'

const defaults: DefaultOptions = {
  queries: {
    retry: 3,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  }
}
export const hubQueryClient = new QueryClient({defaultOptions: defaults})

export const QueryProvider = ({children}: {children: React.ReactNode}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const raiseError = useErrorHandler()

  const onError = (err) => {
    const axiosError = err as AxiosError<Record<string, string>>
    const response = axiosError.response
    const statusCode = response?.status
    const statusText = response?.data?.status ?? response?.statusText
    const url = response?.request?.responseURL

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const message = [
      `Error occurred querying ${url}`,
      `${statusCode}: ${statusText}`,
      response?.data?.detail
    ].join('\n')
    // Removed raising error to prevent unexpected crashing
    // raiseError(new Error(message))
  }

  hubQueryClient.setDefaultOptions({queries: {...defaults.queries, onError}, mutations: {onError}})

  return <QueryClientProvider client={hubQueryClient}>{children}</QueryClientProvider>
}
// extract  the inner type of a promise
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

// get the inner type of the returned promise for a query
type QueryReturn<K extends keyof Queries> = Awaited<ReturnType<Queries[K]>>

/**
 * Wrapper around useQuery from react-query.  Should be used as it allows a type-safe interface into the available queries that we have defined as well as provides a consistent interface.
 * @param queryKey
 * @param params
 * @param options
 */
export const useHubQuery = <K extends keyof Queries>(
  queryKey: K,
  params: Parameters<Queries[K]>,
  options?: UseQueryOptions<QueryReturn<K>>
) => {
  return useQuery<QueryReturn<K>>(
    [queryKey, ...params],
    async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queries[queryKey](...params) as Promise<QueryReturn<K>>
    },
    options
  )
}

type QueryHelper = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]>
) => void

export const invalidateHubQuery: QueryHelper = (queryKey, ...params) =>
  hubQueryClient.invalidateQueries([queryKey, ...params], {refetchActive: true})

export const removeHubQuery: QueryHelper = (queryKey, ...params) =>
  hubQueryClient.removeQueries([queryKey, ...params])

export const refetchHubQuery: QueryHelper = (queryKey, ...params) =>
  hubQueryClient.refetchQueries([queryKey, ...params])

export const setHubQueryData = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  params: Parameters<T[K]>,
  data: QueryReturn<K>
) => hubQueryClient.setQueryData([queryKey, ...params], data)

export const fetchHubQuery: QueryHelper = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]>
) => hubQueryClient.fetchQuery<QueryReturn<K>>([queryKey, ...params])
