import {useHubQuery} from '../../QueryProvider'

export const usePersistedContact = (siteNumber: string) => {
  return useHubQuery('persistedContact', [siteNumber], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!siteNumber
  })
}
